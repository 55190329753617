import React from 'react';
import { Link } from 'gatsby';
import PropTypes, { number } from 'prop-types';
import colors from '../config/color';
import ShoppingCheckPayTitle from '../components/shoppingCheckPayTitle';
import ShoppingOrderCard from '../components/shoppingOrderCard';

const ShoppingOrderDetails = props => {
  const { number } = props;
  return (
    <div className="content">
      <div className="order-number">訂單編號：{number}</div>
      <ShoppingCheckPayTitle titleRight="購物明細" titleLeft="運費" />
      {/* margin-box 的高度要設定，最後一個卡片不會有 margin-bottom */}
      <div className="margin-box">
        <ShoppingOrderCard
          imageUri="https://via.placeholder.com/50x54"
          title="戒指產品名稱戒指產品名稱戒指產品名稱戒指產品名稱"
          spec="彈性男性戒圍 (國際戒圍12-15)"
          quantity="2"
          price="2000"
        />
      </div>
      <ShoppingOrderCard
        imageUri="https://via.placeholder.com/50x54"
        title="戒指產品名稱戒指產品名稱戒指產品名稱戒指產品名稱"
        spec="彈性男性戒圍 (國際戒圍12-15)"
        quantity="2"
        price="2000"
      />
      <hr />

      <style jsx>{`
        .order-number {
          font-size: 14px;
          font-family: SFProDisplay-Regular;
          color: ${colors['black-p']};
          margin-bottom: 20px;
        }

        .margin-box {
          margin-bottom: 20px;
        }

        hr {
          border: none;
          height: 1px;
          background-color: #e0e0e0;
        }

        @media (min-width: 768px) {
        }

        @media (min-width: 1200px) {
        }
      `}</style>
    </div>
  );
};

ShoppingOrderDetails.propTypes = {
  number: PropTypes.string,
  price: PropTypes.number,
};

ShoppingOrderDetails.defaultProps = {
  number: '',
  price: '',
};

export default ShoppingOrderDetails;
