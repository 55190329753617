import React from 'react';
import { Link } from 'gatsby';
import PropTypes, { number } from 'prop-types';
import colors from '../config/color';
import Price from '../components/price';

const ShoppingOrderCard = props => {
  const { imageAlt, imageUri, title, spec, quantity, price } = props;
  return (
    <div className="content">
      <img className="img-content" alt={imageAlt} src={imageUri} />
      <div className="item-content">
        <h2>{title}</h2>
        <div>{spec}</div>
      </div>

      <div className="quantity-content">
        <div>x{quantity}</div>
        <div className="price">
          <Price
            price={price}
            ntSty={{ fontSize: 10 }}
            priceSty={{ fontSize: 14 }}
          />
        </div>
      </div>

      <style jsx>{`
        .content {
          font-size: 12px;
          font-family: SFProDisplay-Regular;
          color: ${colors['black-p']};
          line-height: 18px;
          display: flex;
          flex-direction: row;
          align-items: center;
        }

        .item-content {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          flex: 3;

          height: 54px;
          margin: 0 10px;
          padding-right: 50px;
        }

        .h2 {
          font-family: SFProDisplay-Medium;
        }

        .quantity-content {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          justify-content: space-between;
          flex: 1;
          height: 50px;
          font-size: 14px;
        }

        .price {
          font-size: 10px;
        }

        @media (min-width: 768px) {
        }

        @media (min-width: 1200px) {
        }
      `}</style>
    </div>
  );
};

ShoppingOrderCard.propTypes = {
  imageAlt: PropTypes.string,
  imageUri: PropTypes.string,
  title: PropTypes.string,
  spec: PropTypes.string,
  quantity: PropTypes.string,
  price: PropTypes.number,
};

ShoppingOrderCard.defaultProps = {
  imageAlt: '',
  imageUri: '',
  title: '',
  spec: '',
  quantity: '',
  price: '',
};

export default ShoppingOrderCard;
